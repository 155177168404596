import { useEffect, useState } from "react";

import {
  UNAUTH_ONLY_PAGES,
  POST_AUTHENTICATION_LANDING_PAGE,
} from "src/config/middleware";

// Determine the appropriate landing page after successfully signing in.
// This is a hook since window is not available on server
export const useLandingPage = () => {
  const [landingPage, setLandingPage] = useState(
    POST_AUTHENTICATION_LANDING_PAGE
  );

  useEffect(() => {
    const currentPathname = window.location.pathname;

    // User was on unauth only page, land them on the default post-auth landing page
    if (UNAUTH_ONLY_PAGES.includes(currentPathname)) {
      setLandingPage(POST_AUTHENTICATION_LANDING_PAGE);
    } else {
      // Otherwise, return them back to the page they were on
      setLandingPage(currentPathname);
    }
  }, []);

  return landingPage;
};
