import { Label } from "@proofxyz/moonlight";
import cx from "classnames";

import { SearchResponse } from "src/server/search";

import AccountSearchResult from "./AccountSearchResult";
import ArtistSearchResult from "./ArtistSearchResult";
import styles from "./CombinedSearchResults.module.css";
import GrailSearchResult from "./GrailSearchResult";
import NftSearchResult from "./NftSearchResult";

export const THUMBNAIL_SIZE = 56;

type CombinedSearchResultsProps = {
  results: SearchResponse;
  selectedResult: number;
  setSelectedResult: (value: number) => void;
};

export default function CombinedSearchResults({
  results,
  selectedResult,
  setSelectedResult,
}: CombinedSearchResultsProps) {
  return (
    <>
      {results.artists.length > 0 && (
        <div className={cx(styles.searchResultSection)}>
          <label className={cx(Label.sm, styles.searchSectionLabel)}>
            Artists
          </label>
          {results.artists.map((artist, i) => (
            <ArtistSearchResult
              key={artist.slug}
              artist={artist}
              selected={i === selectedResult}
              onSelectionUpdate={() => setSelectedResult(i)}
            />
          ))}
        </div>
      )}
      {results.accounts.length > 0 && (
        <div className={cx(styles.searchResultSection)}>
          <label className={cx(Label.sm, styles.searchSectionLabel)}>
            Collectors
          </label>
          {results.accounts.map((account, i) => (
            <AccountSearchResult
              key={account.id}
              account={account}
              selected={i + results.artists.length === selectedResult}
              onSelectionUpdate={() =>
                setSelectedResult(i + results.artists.length)
              }
            />
          ))}
        </div>
      )}
      {results.grails.length > 0 && (
        <div className={cx(styles.searchResultSection)}>
          <label className={cx(Label.sm, styles.searchSectionLabel)}>
            Grails
          </label>
          {results.grails.map((grailDetails, i) => (
            <GrailSearchResult
              key={grailDetails.title}
              grailDetails={grailDetails}
              selected={
                i + results.artists.length + results.accounts.length ===
                selectedResult
              }
              onSelectionUpdate={() =>
                setSelectedResult(
                  i + results.artists.length + results.accounts.length
                )
              }
            />
          ))}
        </div>
      )}
      {results.nfts.length > 0 && (
        <div className={cx(styles.searchResultSection)}>
          <label className={cx(Label.sm, styles.searchSectionLabel)}>
            PROOF NFTs
          </label>
          {results.nfts.map((token, i) => (
            <NftSearchResult
              key={token.tokenName}
              token={token}
              selected={
                i +
                  results.artists.length +
                  results.accounts.length +
                  results.grails.length ===
                selectedResult
              }
              onSelectionUpdate={() =>
                setSelectedResult(
                  i +
                    results.artists.length +
                    results.accounts.length +
                    results.grails.length
                )
              }
            />
          ))}
        </div>
      )}
    </>
  );
}
