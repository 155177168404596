import { useEnsName } from "wagmi";

import { getDisplayNames } from "src/lib/user";

import { PublicOrAnonymousUser } from "./identity/types";

/**
 * This function is similar to getProofUser() but it is used when you already
 * have the user resolved, e.g. from getServerSideProps().
 */
export const useDisplayNamesForUser = (user?: PublicOrAnonymousUser) => {
  const firstWalletAddress = user?.addresses?.[0].value;
  const { data: ensName } = useEnsName({
    address: firstWalletAddress,
  });

  return getDisplayNames(user, ensName, firstWalletAddress);
};
