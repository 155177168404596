import { MediaObject, TwoLiner } from "@proofxyz/moonlight";
import classnames from "classnames/bind";
import Link from "next/link";

import Caret, { Direction } from "src/components/Caret";

import styles from "./CombinedSearchResults.module.css";

const cx = classnames.bind(styles);

type SearchResultProps = {
  href: string;
  ariaLabel: string;
  media: React.ReactNode;
  primaryText: React.ReactNode;
  secondaryText: React.ReactNode;
  selected: boolean;
  onSelectionUpdate: () => void;
};

export default function SearchResult({
  href,
  ariaLabel,
  media,
  primaryText,
  secondaryText,
  selected,
  onSelectionUpdate,
}: SearchResultProps) {
  return (
    <Link href={href}>
      <a
        role="menuitem"
        aria-label={ariaLabel}
        className={cx("searchResult", {
          "searchResult--selected": selected,
        })}
        onMouseOver={onSelectionUpdate}
        onFocus={onSelectionUpdate}
      >
        <MediaObject
          media={media}
          body={<TwoLiner primary={primaryText} secondary={secondaryText} />}
          action={<Caret color="secondary" direction={Direction.RIGHT} />}
        />
      </a>
    </Link>
  );
}
