import * as Sentry from "@sentry/nextjs";
import cx from "classnames";
import Head from "next/head";
import type { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";

import ErrorFallback from "src/components/ErrorFallback";
import Footer from "src/components/Footer";
import Header from "src/components/Header";
import { useDarkMode } from "src/hooks/useDarkMode";

import styles from "./Layout.module.css";

export type LayoutProps = PropsWithChildren<{
  className?: cx.Argument;
  transparentUntil?: number;
}>;

const Layout: React.FunctionComponent<LayoutProps> = ({
  transparentUntil,
  children,
  className,
}) => {
  const { mode } = useDarkMode();
  const cn = cx(styles.Layout, className);

  return (
    <>
      <Head>
        <meta
          name="theme-color"
          content={mode === "light" ? "#fbfbfd" : "#000000"}
        />
      </Head>
      <Header transparentUntil={transparentUntil} />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error) => Sentry.captureException(error)}
      >
        <div className={cn}>{children}</div>
      </ErrorBoundary>
      <Footer />
    </>
  );
};

export default Layout;
