import { useQuery, QueryFunctionContext } from "react-query";

import { erc721Contract } from "../web3/contracts";

type Addresses = {
  contractAddress: string;
  tokenId: number;
};

const fetchOwner = async ({
  queryKey,
}: QueryFunctionContext<[string, Addresses]>) => {
  const [_key, { contractAddress, tokenId }] = queryKey;
  const contract = erc721Contract(contractAddress);

  return await contract.ownerOf(tokenId);
};

export const useOwnerOf = (contractAddress: string, tokenId: number) => {
  return useQuery(["ownerOf", { contractAddress, tokenId }], fetchOwner, {
    enabled: !!contractAddress && tokenId !== null && tokenId !== undefined,
  });
};
