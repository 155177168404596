import { useEffect, useState } from "react";

import ConnectorOptions from "src/components/connect/ConnectorOptions";
import { useIsClientConnected } from "src/hooks/useIsClientConnected";

import AlternateSignInOptions from "./AlternateSignInOptions";
import LoginOrCreate from "./LoginOrCreate";

export default function SignInContents() {
  const isClientConnected = useIsClientConnected();

  // Fix hydration issues - is there a flag off useAccount that can be used??
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-6">
        {isClientConnected && isLoaded ? (
          <LoginOrCreate />
        ) : (
          <ConnectorOptions isLoaded={isLoaded} />
        )}

        <hr className="my-2" />

        <AlternateSignInOptions />
      </div>
    </>
  );
}
