import { SearchResult as SearchResultType } from "src/lib/combinedSearch";

import SearchResult from "./SearchResult";
import { useNftSearchResultAttributes } from "./utils";

type NftSearchResultProps = {
  token: SearchResultType;
  selected: boolean;
  onSelectionUpdate: () => void;
};

export default function NftSearchResult({
  token,
  selected,
  onSelectionUpdate,
}: NftSearchResultProps) {
  const { href, ariaLabel, media, primaryText, secondaryText } =
    useNftSearchResultAttributes(token);

  return (
    <SearchResult
      href={href}
      ariaLabel={ariaLabel}
      media={media}
      primaryText={primaryText}
      secondaryText={secondaryText}
      selected={selected}
      onSelectionUpdate={onSelectionUpdate}
    />
  );
}
