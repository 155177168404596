import { Avatar } from "@proofxyz/moonlight";
import Imgix from "react-imgix";

import ContentfulImage from "src/components/ContentfulImage";
import InChainAvatar from "src/components/InChainAvatar";
import { ProofUser } from "src/hooks/identity/types";
import { useDisplayInfoForAddress } from "src/hooks/useDisplayInfoForAddress";
import { useDisplayNamesForUser } from "src/hooks/useDisplayNamesForUser";
import { useOwnerOf } from "src/hooks/useOwnerOf";
import ProfileIcon from "src/icons/profile.svg";
import { SearchResult } from "src/lib/combinedSearch";
import { type ArtistSummary as ArtistSearchResultType } from "src/server/contentful/fragments";
import { type GrailSearchResult as GrailSearchResultType } from "src/server/contentful/search";

import { THUMBNAIL_SIZE } from "./CombinedSearchResults";
import styles from "./CombinedSearchResults.module.css";

export const useNftSearchResultAttributes = (token: SearchResult) => {
  const { data: owner } = useOwnerOf(token.contractAddress, token.tokenId);
  const { displayName, avatarUrl } = useDisplayInfoForAddress(owner);

  return {
    href: token.link,
    ariaLabel: `Link to ${token.tokenName}`,
    primaryText: token.tokenName,
    secondaryText: owner && (
      <div className={styles.byLineWithAvatar}>
        {avatarUrl ? (
          <InChainAvatar src={avatarUrl} size="2xs" />
        ) : (
          <ProfileIcon className="w-6 h-6 fill-current" />
        )}
        {displayName}
      </div>
    ),
    media: (
      <Imgix
        src={token.imageUrl}
        width={THUMBNAIL_SIZE}
        height={THUMBNAIL_SIZE}
        className="rounded"
      />
    ),
  };
};

export const useAccountSearchResultAttributes = (account: ProofUser) => {
  const { primaryDisplayName, secondaryDisplayName } =
    useDisplayNamesForUser(account);

  const {
    profile: { username, avatarUrl },
  } = account;

  return {
    href: `/u/${username}`,
    ariaLabel: `Link to profile for ${username}`,
    primaryText: primaryDisplayName,
    secondaryText: secondaryDisplayName,
    media: <InChainAvatar src={avatarUrl} size="lg" />,
  };
};

export const getGrailSearchResultAttributes = (
  grailDetails: GrailSearchResultType
) => {
  const { artists, thumbnailMedia, title, season, slot } = grailDetails;

  const {
    name,
    avatar: { url },
  } = artists.items[0];

  const secondaryInfo = (
    <div className={styles.byLineWithAvatar}>
      <Avatar src={`${url}?w=96`} size="2xs" />
      {name}
    </div>
  );

  return {
    href: `/grails/season-${season}/${slot.toString().padStart(2, "0")}`,
    ariaLabel: `Link to ${title}`,
    primaryText: title,
    secondaryText: secondaryInfo,
    media: (
      <div className={styles.thumbnailContainer}>
        <ContentfulImage
          src={thumbnailMedia.url}
          w={THUMBNAIL_SIZE}
          h={THUMBNAIL_SIZE}
          q={55}
        />
      </div>
    ),
  };
};

export const getArtistSearchResultAttributes = (
  artist: ArtistSearchResultType
) => {
  const {
    avatar: { url },
    name,
    slug,
  } = artist;

  return {
    href: `/artists/${slug}`,
    ariaLabel: `Link to profile for ${name}`,
    primaryText: name,
    secondaryText: `@${slug}`,
    media: <Avatar src={url} size="lg" />,
  };
};
