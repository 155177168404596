export const POST_AUTHENTICATION_LANDING_PAGE = "/profile";
export const POST_SIGNOUT_LANDING_PAGE = "/signin";

export const UNAUTH_ONLY_PAGES = ["/account/create", "/signin"];

export const AUTH_ONLY_PAGES = [
  "/account/providers",
  "/account/addresses",
  "/account/settings",
  "/profile/edit",
  "/profile",
  // temporarily require auth for these pages in progress
  "/grails/season-1",
  "/grails/season-2",
  "/grails/season-3",
];
