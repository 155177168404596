import { Anchor, Button, ButtonStyle, Text } from "@proofxyz/moonlight";
import Link from "next/link";
import { useRouter } from "next/router";
import truncateEthAddress from "truncate-eth-address";
import { useAccount, useDisconnect } from "wagmi";

import { useAddressExistsQuery } from "src/hooks/identity/useAddressExistsQuery";
import { useAuthentication } from "src/hooks/identity/useAuthentication";
import { useLandingPage } from "src/hooks/useLandingPage";

// This view is only displayed if wallet isConnected

const LoginOrCreate = () => {
  const router = useRouter();
  const { address } = useAccount();
  const landingPage = useLandingPage();
  const { signin } = useAuthentication({
    onSuccess: () => {
      router.push(landingPage);
    },
  });
  const { disconnect } = useDisconnect();

  // If a user object exists, that means they already have an account.
  // If address is not found, there will be an error.
  const { data: addressExists } = useAddressExistsQuery(address);

  return (
    <div className="flex flex-col gap-6">
      <Text color="secondary">
        Now that you&apos;re connected, you&apos;ll need to sign a message to
        verify ownership of this wallet. This will not cost any ETH.
      </Text>
      {address && <Text>Connected wallet: {truncateEthAddress(address)}</Text>}

      <div className="flex flex-col gap-2">
        {addressExists ? (
          <Button onClick={signin}>Sign Message</Button>
        ) : (
          <Link href="/account/create" passHref>
            <Anchor style={ButtonStyle.primary}>Create Account</Anchor>
          </Link>
        )}

        <Button onClick={() => disconnect()} style={ButtonStyle.secondary}>
          Connect a different wallet
        </Button>
      </div>
    </div>
  );
};

export default LoginOrCreate;
