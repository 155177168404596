export enum Direction {
  UP,
  DOWN,
  LEFT,
  RIGHT,
}

type CaretColor = "primary" | "secondary" | "inactive" | "disabled" | "current";

type CaretSize = "sm" | "lg";

export type CaretProps = {
  direction?: Direction;
  color?: CaretColor;
  size?: CaretSize;
  hoverable?: boolean;
  onClick?: () => void;
  className?: string;
};
