export type ContentfulImageProps = {
  src: string;
  alt?: string;

  fm?: string;
  w?: number;
  h?: number;
  fit?: string;
  f?: string;
  q?: number;
};

const ContentfulImage: React.FunctionComponent<ContentfulImageProps> = ({
  src,
  alt,
  ...options
}) => {
  const optionsWithStringValues = options
    ? Object.entries(options).reduce(
        (prev, [key, val]) => ({ ...prev, [key]: val.toString() }),
        {}
      )
    : {};
  const urlSearchParams = new URLSearchParams(optionsWithStringValues);

  return <img src={`${src}?${urlSearchParams}`} alt={alt} />;
};

export default ContentfulImage;
