import { SearchResponse } from "src/server/search";
import { contracts } from "src/web3/constants";

export type Collection = {
  contractAddress: string;
  tokenName(id: number): string;
  imageUrl(id: number): string;
  link: (id: number) => string;
  maxId: number;
  matches: string[];
};

export type SearchResult = {
  contractAddress: Collection["contractAddress"];
  tokenName: ReturnType<Collection["tokenName"]>;
  tokenId: number;
  imageUrl: ReturnType<Collection["imageUrl"]>;
  link: ReturnType<Collection["link"]>;
};

export const COLLECTIONS = {
  [contracts.MOONBIRDS]: {
    tokenName: (id: number | string) => `Moonbird #${id}`,
    imageUrl: (id: number | string) => `https://moonbirds.imgix.net/${id}`,
    link: (id: number | string) => `/moonbirds/${id}`,
    maxId: 9999,
    matches: ["moonbirds", "moon", "bird"],
  },
  [contracts.ODDITIES]: {
    tokenName: (id: number | string) => `Oddity #${id}`,
    imageUrl: (id: number | string) =>
      `https://moonbirds-oddities.imgix.net/${id}`,
    link: (id: number | string) => `/oddities/${id}`,
    maxId: 9999,
    matches: ["oddity", "oddities", "odd"],
  },
  [contracts.PROOF_COLLECTIVE]: {
    tokenName: (id: number | string) => `PROOF Collective #${id}`,
    imageUrl: (_id: number | string) =>
      "https://proof-xyz.imgix.net/avatars/collective.jpg",
    link: (id: number | string) => `/collective/${id}`,
    maxId: 999,
    matches: ["proof", "collective"],
  },
};

const emptyResults: SearchResponse = {
  nfts: [],
  accounts: [],
  grails: [],
  artists: [],
};

export async function combinedSearch(query: string): Promise<SearchResponse> {
  // Some simple input sanitization. Removes spaces and # symbols
  const sanitizedQuery = sanitizeQuery(query);

  if (sanitizedQuery.length === 0) {
    return emptyResults;
  }

  let nfts: SearchResult[] = [];

  if (sanitizedQuery.match(/^\d+$/)) {
    nfts = filterByNumericalTerms([parseInt(sanitizedQuery, 10)]);
  }

  if (sanitizedQuery.length >= 3) {
    const params = new URLSearchParams();
    params.append("q", sanitizedQuery);

    const resp = await fetch(`/api/search?${params.toString()}`);
    if (resp.ok) {
      const data = await resp.json();
      return {
        nfts: nfts.concat(data.nfts),
        accounts: data.accounts,
        grails: data.grails,
        artists: data.artists,
      };
    }
  }

  return { ...emptyResults, nfts };
}

const sanitizeQuery = (query: string) => query.trim().replace(/#/g, "");

const filterByNumericalTerms = (terms: number[]) => {
  const searchResults: SearchResult[] = [];
  const eligibleCollections = Object.keys(COLLECTIONS);

  if (terms.length > 0) {
    // There are numbers in our query, so lets start filtering the results
    terms.forEach((num) => {
      eligibleCollections.forEach((collectionAddr) => {
        // If the number entered is less than the max token ID, let's include it
        if (num <= COLLECTIONS[collectionAddr].maxId) {
          const collection = COLLECTIONS[collectionAddr];
          searchResults.push({
            contractAddress: collectionAddr,
            tokenName: collection.tokenName(num),
            tokenId: num,
            imageUrl: collection.imageUrl(num),
            link: collection.link(num),
          });
        }
      });
    });
  } else {
    // No numbers in the query, let's just return token ID 0
    eligibleCollections.forEach((collectionAddr) => {
      const collection = COLLECTIONS[collectionAddr];
      searchResults.push({
        contractAddress: collectionAddr,
        tokenName: collection.tokenName(0),
        tokenId: 0,
        imageUrl: collection.imageUrl(0),
        link: collection.link(0),
      });
    });
  }

  return searchResults;
};
