import { Text } from "@proofxyz/moonlight";
import Link from "next/link";

import type { FooterLinkProps } from "./FooterLink.types";

const FooterLink: React.FunctionComponent<FooterLinkProps> = ({
  href,
  text,
}) => {
  const isExternalLink = !href.startsWith("/");

  const contents = (
    <Text size="md" color="secondary" className="hover:underline">
      {text}
    </Text>
  );

  if (isExternalLink) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {contents}
      </a>
    );
  }

  return (
    <Link href={href}>
      <a>{contents}</a>
    </Link>
  );
};

export default FooterLink;
