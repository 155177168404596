import { Avatar } from "@proofxyz/moonlight";
import cx from "classnames";
import React from "react";

import { useMoonbirdInChain } from "src/hooks/useMoonbirdInChain";
import {
  getMoonbirdsInChainImageUrl,
  parseMoonbirdsTokenId,
} from "src/lib/inChain";

import styles from "./InChainAvatar.module.css";
import type { InChainAvatarProps } from "./InChainAvatar.types";

const InChainAvatar: React.FunctionComponent<InChainAvatarProps> = (props) => {
  let avatarProps = props;

  // Use in-chain Moonbird image if available.
  const moonbirdsTokenId = parseMoonbirdsTokenId(props.src);
  const isInChain = useMoonbirdInChain(moonbirdsTokenId);

  if (isInChain) {
    avatarProps = {
      ...props,
      src: getMoonbirdsInChainImageUrl(moonbirdsTokenId),
      className: cx(props.className, styles.InChainAvatar),
    };
  }

  return <Avatar {...avatarProps} />;
};

export default InChainAvatar;
