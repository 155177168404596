import {
  Button,
  ButtonStyle,
  ButtonSize,
  Color,
  Anchor,
} from "@proofxyz/moonlight";
import Link from "next/link";
import { useState } from "react";
import { useAccount } from "wagmi";

import { useAddressExistsQuery } from "src/hooks/identity/useAddressExistsQuery";
import { useIsClientConnected } from "src/hooks/useIsClientConnected";

import SigninModal from "../SignInModal";

export type SignInButtonProps = {
  className?: string;
  color?: Color;
  style?: ButtonStyle;
  size?: ButtonSize;
  text?: string;
  onClick?: () => void;
};

const SignInButton: React.FC<SignInButtonProps> = ({
  className,
  color = Color.neutral,
  style = ButtonStyle.secondary,
  size = "md",
  text,
  onClick,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const isClientConnected = useIsClientConnected();
  const { address } = useAccount();
  const { data: addressExists, isSuccess: addressCheckSuccess } =
    useAddressExistsQuery(address);

  // In the instances where we would show a sign in button, if the user is already connected,
  // but doesnt have an account, show a Create Account button instead.
  const showCreateAccountButton =
    isClientConnected && addressCheckSuccess && !addressExists;

  if (showCreateAccountButton) {
    return (
      <Link href="/account/create" passHref>
        <Anchor style={style} color={color} size={size}>
          Create account
        </Anchor>
      </Link>
    );
  }

  return (
    <>
      {modalOpen && <SigninModal onRequestClose={() => setModalOpen(false)} />}
      <Button
        className={className}
        style={style}
        color={color}
        size={size}
        onClick={() => {
          setModalOpen(true);
          if (onClick) onClick();
        }}
      >
        {text ?? "Sign in"}
      </Button>
    </>
  );
};

export default SignInButton;
