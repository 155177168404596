import Discord from "src/icons/discord.svg";
import Instagram from "src/icons/instagram.svg";
import OpenSea from "src/icons/opensea.svg";
import Twitter from "src/icons/twitter.svg";
import { OPENSEA_URL } from "src/web3/constants";

const config = {
  linkGroups: {
    collections: [
      { text: "Collective", href: "/collective" },
      { text: "Moonbirds", href: "/moonbirds" },
      { text: "Oddities", href: "/oddities" },
      { text: "Grails", href: "/grails/season-3" },
    ],
    company: [
      { text: "Contact Us", href: "https://support.proof.xyz" },
      { text: "Podcast", href: "https://podcasts.proof.xyz/" },
      { text: "Shop", href: "https://www.propaganda.xyz" },
    ],
    logistical: [
      { text: "Careers", href: "https://boards.greenhouse.io/proofxyz" },
      { text: "Documentation", href: "https://docs.proof.xyz" },
      { text: "Privacy Policy", href: "/privacy" },
      { text: "Terms of Use", href: "/terms" },
    ],
  },
  socials: [
    {
      href: "https://twitter.com/proof_xyz",
      icon: Twitter,
      name: "twitter",
    },
    {
      href: "https://discord.gg/proof",
      icon: Discord,
      name: "discord",
    },
    {
      href: `${OPENSEA_URL}/PROOF_XYZ?tab=created`,
      icon: OpenSea,
      name: "opensea",
    },
    {
      href: `https://www.instagram.com/proofxyz/`,
      icon: Instagram,
      name: "instagram",
    },
  ],
};

export default config;
