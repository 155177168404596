/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { OwnedNft } from "alchemy-sdk";

import { IMGIX_NFT_URL, IMGIX_NFT_ANIMATION_URL } from "src/web3/constants";

export const getImgixDomain = (url: string) => {
  const urlObject = new URL("/", url);
  return urlObject.hostname.split(".")[0];
};

export const ensureImgixDomain = (url: string, domain: string) => {
  if (!/imgix/.test(domain)) domain = `${domain}.imgix.net`;

  const parsedUrl = new URL(url, `https://${domain}`);

  parsedUrl.protocol = "https";
  if (parsedUrl.host !== domain) {
    parsedUrl.host = domain;
  }

  return decodeURIComponent(parsedUrl.toString());
};

const getImgixUrl = (nft: OwnedNft): string => {
  return getImgixUrlForToken(nft.contract.address, nft.tokenId);
};

export const getImgixUrls = (ownedNfts?: OwnedNft[]) => {
  if (!ownedNfts) {
    return [];
  }

  return ownedNfts.map((nft) => getImgixUrl(nft));
};

export const getImgixUrlForToken = (
  contractAddress: string,
  tokenId: number | string
) => {
  return `${IMGIX_NFT_URL}/${contractAddress}/${tokenId}`;
};

export const getImgixAnimationUrlForToken = (
  contractAddress: string,
  tokenId: number | string
) => {
  return `${IMGIX_NFT_ANIMATION_URL}/${contractAddress}/${tokenId}`;
};

export const purgeImgixCacheForToken = async (
  contractAddress: string,
  tokenId: number | string
) => {
  const resp = await fetch(
    `/api/nfts/image/${contractAddress}/${tokenId}/purge`,
    {
      method: "POST",
    }
  );

  return resp.ok;
};
