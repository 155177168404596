import { type GrailSearchResult as GrailSearchResultType } from "src/server/contentful/search";

import SearchResult from "./SearchResult";
import { getGrailSearchResultAttributes } from "./utils";

type GrailSearchResultProps = {
  grailDetails: GrailSearchResultType;
  selected: boolean;
  onSelectionUpdate: () => void;
};

export default function GrailSearchResult({
  grailDetails,
  selected,
  onSelectionUpdate,
}: GrailSearchResultProps) {
  const { href, ariaLabel, media, primaryText, secondaryText } =
    getGrailSearchResultAttributes(grailDetails);

  return (
    <SearchResult
      href={href}
      ariaLabel={ariaLabel}
      media={media}
      primaryText={primaryText}
      secondaryText={secondaryText}
      selected={selected}
      onSelectionUpdate={onSelectionUpdate}
    />
  );
}
