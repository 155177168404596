import { type ArtistSummary as ArtistSearchResultType } from "src/server/contentful/fragments";

import SearchResult from "./SearchResult";
import { getArtistSearchResultAttributes } from "./utils";

type ArtistSearchResultProps = {
  artist: ArtistSearchResultType;
  selected: boolean;
  onSelectionUpdate: () => void;
};

export default function ArtistSearchResult({
  artist,
  selected,
  onSelectionUpdate,
}: ArtistSearchResultProps) {
  const { href, ariaLabel, media, primaryText, secondaryText } =
    getArtistSearchResultAttributes(artist);

  return (
    <SearchResult
      href={href}
      ariaLabel={ariaLabel}
      media={media}
      primaryText={primaryText}
      secondaryText={secondaryText}
      selected={selected}
      onSelectionUpdate={onSelectionUpdate}
    />
  );
}
