import { type ValidStampNames } from "src/components/Stamp/dataForStamp";
import traits from "src/server/metadata/traits";

const { moonbirds: moonbirdTraits } = traits;
export type MoonbirdTrait = typeof moonbirdTraits[number];

type DateString = string; // ex "2022-07-07T09:01:26.000Z"

export type NestLevel = "Straw" | "Bronze" | "Silver" | "Gold" | "Diamond";

export type NestingDetails = {
  currentNestingPeriodStartedAt: DateString | null;
  currentNestLevelStartedAt: DateString | null;
  nested: boolean;
  nestLevel: NestLevel | null;
  nextNestLevelAt: DateString | null;
  currentDuration: number;
  nestProgress: number | null;
  totalDuration: number;
};

type NestingTier = {
  name: NestLevel;
  start: number;
  duration: number;
  image: string;
  largeImage: string;
};

export type NestInfo = {
  previous: NestingTier;
  current: NestingTier;
  next: NestingTier;
  progress: number;
  timeInCurrentStr: string;
  timeTilNext: number;
  timeTilNextStr: string;
  totalNestingTime: number;
  totalNestingTimeStr: string;
};

// Legacy object returned as part of `collector` from birdwatching v1's /users/:id endpoint
export type Moonbird = {
  contentURI: string;
  id: number;
  nesting: NestingDetails;
  owner: string;
  traits: Record<MoonbirdTrait, string>;
};

export type Reward = {
  id: string;
  tokenId: number;
  nestLevel: NestLevel;
  title: string;
  description: string;
  imageUrl: string;
  redemptionUrl: string;
  redeemed: boolean;
  redeemedAt: boolean;
  createdAt: DateString;
};

export type RewardData = {
  tokenId?: number;
  eligibleNests: NestLevel[];
  rewards: Reward[];
};

// Legacy object returned as part of `collector` from birdwatching v1's /users/:id endpoint
export type Badge = {
  earnedAt: string; // "2022-06-23T11:00:00.000Z";
  name: ValidStampNames;
  owner: string; // wallet address
};

export function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !("nodeType" in e)) {
    throw new Error(`Node expected`);
  }
}
