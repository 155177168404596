import { TabItem, Label, Text } from "@proofxyz/moonlight";
import cx from "classnames";
import Link from "next/link";
import { useState } from "react";
import truncateEthAddress from "truncate-eth-address";
import { useAccount, useDisconnect } from "wagmi";

import ConnectModal from "src/components/connect/ConnectModal";
import { useAuthentication } from "src/hooks/identity/useAuthentication";
import { useIdentity } from "src/hooks/useIdentity";
import { useIsClientConnected } from "src/hooks/useIsClientConnected";

import InChainAvatar from "../../InChainAvatar";

const ProfileDropdownContents: React.FC = () => {
  const { viewer } = useIdentity();
  const { signout } = useAuthentication({});
  const { address } = useAccount();
  const isClientConnected = useIsClientConnected();
  const [modalOpen, setModalOpen] = useState(false);
  const { disconnect } = useDisconnect();

  const primaryDisplayName = viewer?.profile.displayName
    ? viewer?.profile.displayName
    : `@${viewer?.profile.username}`;

  const secondaryDisplayName = viewer?.profile.displayName
    ? `@${viewer?.profile.username}`
    : isClientConnected && address
    ? truncateEthAddress(address)
    : "No wallet connected";

  return (
    <div>
      <div className="flex flex-col items-start gap-4">
        <Link href={`/u/${viewer?.profile.username}`}>
          <a className="flex gap-2 items-center w-full text-left">
            <InChainAvatar src={viewer?.profile.avatarUrl} size="md" />
            <div className="flex-1">
              <p className={cx(Label.md, "text-content-primary")}>
                {primaryDisplayName}
              </p>

              <Text size="sm" color="secondary">
                {secondaryDisplayName}
              </Text>
            </div>
          </a>
        </Link>
        {!isClientConnected ? (
          <TabItem onClick={() => setModalOpen(true)} size="sm">
            Connect wallet
          </TabItem>
        ) : (
          <TabItem onClick={disconnect} size="sm">
            Disconnect wallet
          </TabItem>
        )}
        <Link href="/profile/edit" passHref>
          <TabItem element="a" size="sm">
            Edit profile
          </TabItem>
        </Link>
        <Link href="/account/settings" passHref>
          <TabItem element="a" size="sm">
            Account settings
          </TabItem>
        </Link>
        <TabItem size="sm" onClick={signout}>
          Sign out
        </TabItem>
      </div>
      {modalOpen && !isClientConnected && (
        <ConnectModal onRequestClose={() => setModalOpen(false)} />
      )}
    </div>
  );
};

export default ProfileDropdownContents;
