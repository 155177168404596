import { Signer } from "ethers";

export type SiweMutationOptions = {
  address: string;
  chainId: number;
  signer: Signer;
};

enum Chain {
  ETHEREUM = "ethereum",
}

export type WalletAddress = {
  accountId: string;
  chain: Chain;
  createdAt: string;
  id: string;
  isBlocked: false;
  updatedAt: string;
  value: string;
};

export enum ThirdPartyProviderName {
  TWITTER = "twitter",
  GOOGLE = "google",
  DISCORD = "discord",
}

export type ThirdPartyIdentity = {
  accountId: string;
  createdAt: string;
  id: string;
  name?: string;
  avatarUrl?: string;
  isBlocked: boolean;
  isHidden: boolean;
  provider: ThirdPartyProviderName;
  providerId: string;
  refreshToken?: string;
  updatedAt: string;
};

enum UserStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

enum ProofUserRole {
  USER = "user",
  ADMIN = "admin",
  EMPLOYEE = "employee",
}

// Object returned for authenticated calls to modify/fetch own user information
export type ProofUser = {
  id: string;
  status: UserStatus;
  createdAt: string;
  updatedAt: string;
  role: ProofUserRole;
  identities?: ThirdPartyIdentity[];
  addresses?: WalletAddress[];
  profile: {
    username: string;
    // nullable fields, but always returned. EMPTY on the database is ''
    avatarUrl: string | null;
    description: string | null;
    displayName: string | null;
    bannerUrl: string | null;
    email: string | null;
  };
};

// Double-check with Luke to see if this is right...
export type PublicUser = ProofUser;

export type AnonymousUser = {
  // isAnonymous is a 'discriminator' to easily determine if certain fields (description, displayName) exist
  isAnonymous: true;
  addresses: { chain: "ethereum"; value: string }[];
  profile: {
    avatarUrl: PublicUser["profile"]["avatarUrl"];
    bannerUrl: PublicUser["profile"]["bannerUrl"];
    username: PublicUser["profile"]["username"];
  };
};

export type PublicOrAnonymousUser =
  | AnonymousUser
  | ({
      isAnonymous?: false;
    } & PublicUser);
