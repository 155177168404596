import Link from "next/link";

import styles from "./Footer.module.css";
import type { FooterIconButtonProps } from "./FooterIconButton.types";

const FooterIconButton: React.FunctionComponent<FooterIconButtonProps> = ({
  href,
  icon: Icon,
}) => {
  const name = Icon.name.slice(3);
  return (
    <Link href={href}>
      <a target="_blank" rel="noreferrer">
        <Icon className={styles.icon} alt={`${name} logo`} />
      </a>
    </Link>
  );
};

export default FooterIconButton;
