import React, { useState } from "react";

import styles from "./Navigation.module.css";
import type { ActiveNavTitle, SubnavItem } from "./Navigation.types";
import NavigationDropdown from "./dropdowns/NavigationDropdown";

const COMMUNITY_SUBNAV_ITEMS: SubnavItem[] = [
  {
    name: "Collective",
    path: "/collective",
  },
  {
    name: "Moonbirds",
    path: "/moonbirds",
  },
  {
    name: "Oddities",
    path: "/oddities",
  },
  {
    name: "Lunar Society",
    path: "/society",
  },
  {
    name: "Rewards",
    path: "/rewards",
  },
  {
    name: "Builders",
    path: "/builders",
  },
  {
    name: "Events",
    path: "https://events.proof.xyz/",
  },
];

const ART_SUBNAV_ITEMS: SubnavItem[] = [
  {
    name: "Artists",
    path: "/artists",
  },
  {
    name: "Grails I",
    path: "/grails/season-1",
  },
  {
    name: "Grails II",
    path: "/grails/season-2",
  },
  {
    name: "Grails III",
    path: "/grails/season-3",
  },
];

const MEDIA_SUBNAV_ITEMS: SubnavItem[] = [
  {
    name: "Podcast",
    path: "https://podcasts.proof.xyz/",
  },
  {
    name: "Daily Countdown",
    path: "https://www.youtube.com/playlist?list=PLsvBBjkB4w7b1rL4uzAqM894nzYxDc2-1",
  },
];

const Navigation: React.FunctionComponent = () => {
  const [activeNavTitle, setActiveNavTitle] = useState<ActiveNavTitle>(null);

  return (
    <nav className={styles.Navigation}>
      <NavigationDropdown
        title="Community"
        subnavItems={COMMUNITY_SUBNAV_ITEMS}
        activeNavTitle={activeNavTitle}
        setActiveNavTitle={setActiveNavTitle}
      />

      <NavigationDropdown
        title="Art"
        subnavItems={ART_SUBNAV_ITEMS}
        activeNavTitle={activeNavTitle}
        setActiveNavTitle={setActiveNavTitle}
      />

      <NavigationDropdown
        title="Media"
        subnavItems={MEDIA_SUBNAV_ITEMS}
        activeNavTitle={activeNavTitle}
        setActiveNavTitle={setActiveNavTitle}
      />
    </nav>
  );
};

export default Navigation;
