import { Text } from "@proofxyz/moonlight";

import Globe from "public/images/icons/globe.svg";
import Proof from "public/images/icons/proof.svg";

import footerConfig from "./Footer.config";
import styles from "./Footer.module.css";
import FooterIconButton from "./FooterIconButton";
import FooterLinkGroup from "./FooterLinkGroup";

const Footer: React.FunctionComponent = () => {
  const footerLinkGroups = Object.entries(footerConfig.linkGroups).map(
    ([heading, linkGroupItems]) => (
      <FooterLinkGroup
        key={`footer-link-group-${heading}`}
        linkGroupItems={linkGroupItems}
      />
    )
  );

  const footerIcons = footerConfig.socials.map((socialPlatform) => (
    <FooterIconButton {...socialPlatform} key={socialPlatform.icon.name} />
  ));

  return (
    <footer className={styles.Footer}>
      <div>
        {/* divider */}
        <div className={styles.dividerContainer}>
          <hr className="flex-1" />
          <Globe className={styles.globe} />
          <hr className="flex-1" />
        </div>

        {/* links */}
        <div className={styles.content}>
          {/* logo */}
          <div className={styles.wordmarkContainer}>
            <Proof className={styles.wordmark} />
            <Text size="xs" color="inactive">
              &copy; {new Date().getFullYear()} PROOF Holdings
            </Text>
          </div>

          {/* link groups */}
          <nav
            className={styles.navigation}
            aria-labelledby="footer-navigation"
          >
            <h1 id="footer-navigation" className="screen-reader-only">
              Footer navigation
            </h1>
            {footerLinkGroups}
          </nav>

          {/* icons */}
          <div className={styles.iconGroup}>{footerIcons}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
