import { Text } from "@proofxyz/moonlight";
import { useConnect } from "wagmi";

import Connectors from "src/components/connect/Connectors";

export default function ConnectorOptions({ isLoaded }: { isLoaded: boolean }) {
  const { error } = useConnect();

  return (
    <>
      <Text color="secondary">
        Connecting your wallet grants instant access to your Collector Profile
        and exclusive, token-gated features based on your holdings.
      </Text>
      {error && (
        <Text color="error" className="text-center">
          Connection error: {error.message ?? "Failed to connect."}
        </Text>
      )}
      <div className="flex flex-col gap-2">{isLoaded && <Connectors />}</div>
    </>
  );
}
