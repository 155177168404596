import { Button, ButtonStyle, Color } from "@proofxyz/moonlight";
import { useConnect } from "wagmi";

export default function Connectors() {
  const { connect, connectors } = useConnect();

  return (
    <>
      {connectors
        .filter((connector) => connector.ready)
        .map((connector) => (
          <Button
            color={Color.neutral}
            style={ButtonStyle.secondary}
            fullWidth
            disabled={!connector.ready}
            key={connector.id}
            onClick={async () => {
              connect({ connector });
            }}
          >{`${connector.name}${
            !connector.ready ? " (unsupported)" : ""
          }`}</Button>
        ))}
    </>
  );
}
