import { TabItem } from "@proofxyz/moonlight";
import classnames from "classnames/bind";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import useMedia from "use-media";

import Caret, { Direction } from "src/components/Caret";

import type { NavTitle, ActiveNavTitle, SubnavItem } from "../Navigation.types";
import styles from "./NavigationDropdown.module.css";
import dropdownStyles from "./dropdowns.module.css";

const cx = classnames.bind({ ...styles, ...dropdownStyles });

type NavigationItemProps = {
  title: NavTitle;
  subnavItems: SubnavItem[];
  activeNavTitle: ActiveNavTitle;
  setActiveNavTitle: (navItem: ActiveNavTitle) => void;
};

const NavigationDropdown: React.FunctionComponent<NavigationItemProps> = ({
  title,
  subnavItems,
  activeNavTitle,
  setActiveNavTitle,
}) => {
  const router = useRouter();
  const isOpen = activeNavTitle === title;

  const isDesktop = useMedia({ minWidth: 640 });

  const isThisTabActive = subnavItems.some((c) =>
    router.pathname.startsWith(c.path)
  );

  return (
    <div
      className={cx("headerDropdown", "navItem", {
        "navItem--open": isOpen,
      })}
      onMouseEnter={() => {
        if (isDesktop) setActiveNavTitle(title);
      }}
      onMouseLeave={() => {
        if (isDesktop) setActiveNavTitle(null);
      }}
      onClick={() => setActiveNavTitle(isOpen ? null : title)}
    >
      <TabItem active={isThisTabActive ? true : undefined} size="sm">
        <div className="flex gap-1">
          {title}
          <Caret color="current" direction={Direction.DOWN} size="sm" />
        </div>
      </TabItem>

      <div
        className={cx("dropdownMenu", "dropdownMenu--nav", {
          "dropdownMenu--open": isOpen,
        })}
      >
        <div
          className={cx("dropdownContents", {
            "dropdownContents--box": isDesktop,
          })}
        >
          {subnavItems.map((subnavItem) => (
            <Link key={subnavItem.path} href={subnavItem.path} passHref>
              <TabItem
                element="a"
                size="sm"
                active={router.pathname.startsWith(subnavItem.path)}
              >
                {subnavItem.name}
              </TabItem>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NavigationDropdown;
