import { TextInput } from "@proofxyz/moonlight";
import classnames from "classnames/bind";
import { useEffect, useRef } from "react";

import LoadingIcon from "public/images/icons/loading.svg";
import SearchIcon from "public/images/icons/search.svg";
import useSearch from "src/hooks/useSearch";
import CloseIcon from "src/icons/close.svg";

import CombinedSearchResults from "./CombinedSearchResults";
import styles from "./SearchBox.module.css";

const cx = classnames.bind(styles);

type SearchBoxProps = {
  setHasSearchQuery: (hasQuery: boolean) => void;
  onClick: () => void;
};

export default function SearchBox({
  setHasSearchQuery,
  onClick,
}: SearchBoxProps) {
  const searchContainerRef = useRef(null);
  const inputRef = useRef(null);

  const {
    inputProps,
    isLoading,
    results,
    resultsVisible,
    selectedResult,
    setSelectedResult,
    clearQuery,
    query,
  } = useSearch(
    searchContainerRef,
    inputRef,
    { bindEventListeners: true },
    onClick
  );

  useEffect(() => {
    setHasSearchQuery(!!query);
  }, [query, setHasSearchQuery]);

  let rightIcon;
  if (isLoading) {
    rightIcon = <LoadingIcon className={cx("rightIcon", "animate-spin")} />;
  } else if (query) {
    rightIcon = <CloseIcon className={cx("rightIcon")} onClick={clearQuery} />;
  }

  return (
    <div className="relative" ref={searchContainerRef} role="search">
      <TextInput
        ref={inputRef}
        icon={<SearchIcon className={cx("SearchIcon")} />}
        rightIcon={rightIcon}
        size="sm"
        className="xl:min-w-[280px]"
        {...inputProps}
      />

      {results && resultsVisible ? (
        <div
          role="menu"
          aria-live="polite"
          className={cx("SearchResults", "fadeIn")}
        >
          <CombinedSearchResults
            results={results}
            selectedResult={selectedResult}
            setSelectedResult={setSelectedResult}
          />
        </div>
      ) : null}
    </div>
  );
}
