import { ProofUser } from "src/hooks/identity/types";

import SearchResult from "./SearchResult";
import { useAccountSearchResultAttributes } from "./utils";

type AccountSearchResultProps = {
  account: ProofUser;
  selected: boolean;
  onSelectionUpdate: () => void;
};

export default function AccountSearchResult({
  account,
  selected,
  onSelectionUpdate,
}: AccountSearchResultProps) {
  const { href, ariaLabel, media, primaryText, secondaryText } =
    useAccountSearchResultAttributes(account);

  return (
    <SearchResult
      href={href}
      ariaLabel={ariaLabel}
      media={media}
      primaryText={primaryText}
      secondaryText={secondaryText}
      selected={selected}
      onSelectionUpdate={onSelectionUpdate}
    />
  );
}
