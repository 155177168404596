import FooterLink from "./FooterLink";
import type { FooterLinkGroupProps } from "./FooterLinkGroup.types";

const Footer: React.FunctionComponent<FooterLinkGroupProps> = ({
  linkGroupItems,
}) => {
  return (
    <ul className="flex flex-col gap-2 flex-grow">
      {linkGroupItems.map((linkGroupItem) => {
        const { text, href } = linkGroupItem;
        return (
          <FooterLink
            key={`footer-link-group-item-${text}`}
            href={href}
            text={text}
          />
        );
      })}
    </ul>
  );
};

export default Footer;
