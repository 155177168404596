import { useEffect, useState } from "react";
import { useAccount } from "wagmi";

// 🤡 This silliness is used to prevent the client from rendering during
// reconnection. The update to wagmi ^0.6 introduced an issue where the use of
// isConnected in Layout (and other components) caused the client to render
// differently than the SSR version, which caused a hydration error. The new
// version of wagmi behaves differently that the old version in that it sets
// isConnected to true immediately during reconnection and the other waited a
// tick (matching the server-side render).
export const useIsClientConnected = () => {
  // The initial value of this can be true because wagmi sets its default value
  // from its localStorage cache, which means that the initial render of the page
  // will differ from the server-rendered version.
  const { isConnected } = useAccount();
  const [clientConnected, setClientConnected] = useState(false);

  useEffect(() => {
    // By using useEffect, this code does not run on the server due to how
    // Next.js hydration works. So clientConnected won't be true until the page
    // renders client-side, so the initial render will match the server.
    setClientConnected(isConnected);
  }, [isConnected]);

  return clientConnected;
};
