import classnames from "classnames/bind";
import { useEffect } from "react";

import styles from "./MobileNavigationMenu.module.css";
import Navigation from "./Navigation";
import ThemeToggle from "./ThemeToggle";

const cx = classnames.bind(styles);

const MobileNavigationMenu: React.FC = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return (
    <div className={cx("MobileNavigationMenu")}>
      <div className="flex flex-col gap-8 items-center">
        <Navigation />
        <ThemeToggle />
      </div>
    </div>
  );
};

export default MobileNavigationMenu;
