import { Modal } from "@proofxyz/moonlight";

import { useIsClientConnected } from "src/hooks/useIsClientConnected";

import SignInContents from "./SignInContents";

type SignInModalProps = {
  onRequestClose: () => void;
  allowClose?: boolean;
};

export default function SignInModal({
  onRequestClose,
  allowClose = true,
}: SignInModalProps) {
  const isClientConnected = useIsClientConnected();

  return (
    <Modal
      className="max-w-lg"
      title={!isClientConnected ? "Select a wallet" : "Sign in"}
      onRequestClose={onRequestClose}
      allowClose={allowClose}
    >
      <SignInContents />
    </Modal>
  );
}
