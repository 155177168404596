import * as Sentry from "@sentry/nextjs";
import { useCallback } from "react";
import { useMutation } from "react-query";
import { useAccount, useSigner, useNetwork } from "wagmi";

import { useIdentity } from "src/hooks/useIdentity";
import {
  fetchMe,
  authenticatedFetch,
  getNonceAndSignMessage,
  deleteRefreshToken,
  setAccessToken,
  setRefreshToken,
  getRefreshToken,
} from "src/queries/identity";

import type { ProofUser, SiweMutationOptions } from "./types";

const signinMutation = async ({
  address,
  chainId,
  signer,
}: SiweMutationOptions): Promise<ProofUser> => {
  const { message, signature } = await getNonceAndSignMessage({
    address,
    chainId,
    signer,
  });

  const signinResponse = await authenticatedFetch("/v1/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ message, signature }),
  });

  if (!signinResponse.ok) throw new Error("Error verifying message");

  const data = await signinResponse.json();

  setAccessToken(data.accessToken);
  setRefreshToken(data.refreshToken);

  // Upon verification, if a user exists, return it.
  return await fetchMe();
};

type UseAuthenticationOptions = {
  onSuccess?: () => void;
};

export const useAuthentication = ({ onSuccess }: UseAuthenticationOptions) => {
  const { setViewer } = useIdentity();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { data: signer } = useSigner();

  const { mutate, error, isSuccess } = useMutation(signinMutation, {
    onSuccess: (proofUser) => {
      setViewer(proofUser);
      if (onSuccess) onSuccess();
    },
    onError: (e) => {
      Sentry.captureException(e);
    },
  });

  const signin = useCallback(() => {
    if (address && chain?.id && signer) {
      mutate({
        address,
        chainId: chain?.id,
        signer,
      });
    }
  }, [address, chain, signer, mutate]);

  const signout = async () => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      try {
        await deleteRefreshToken(refreshToken);
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    setViewer(null);
  };

  return {
    signin,
    signout,
    error,
    isSuccess,
  };
};
