const traits = {
  moonbirds: [
    "Background",
    "Beak",
    "Body",
    "Eyes",
    "Eyewear",
    "Feathers",
    "Headwear",
    "Outerwear",
  ],
  oddities: [
    "Attitude",
    "Species",
    "Head",
    "Background",
    "Underfoot",
    "Shoes",
    "Eyes",
    "Eyewear",
    "Outerwear",
    "Headwear",
    "Body",
  ],
};

export default traits;
