import { DEFAULT_AVATAR_URL } from "@proofxyz/moonlight/src/Avatar";
import type { FetchEnsNameResult } from "@wagmi/core";
import truncateEthAddress from "truncate-eth-address";

import { DEFAULT_BANNER_URL } from "src/components/editProfile/consts";
import {
  type ThirdPartyIdentity,
  type AnonymousUser,
  type PublicOrAnonymousUser,
  ThirdPartyProviderName,
} from "src/hooks/identity/types";
import { ensureImgixDomain } from "src/lib/imgix";
import { type PublicUser as DeprecatedPublicUser } from "src/pages/api/deprecated/users/[accountId]";

export function createAnonymousUser(address: string): AnonymousUser {
  return {
    isAnonymous: true,
    addresses: [{ chain: "ethereum", value: address }],
    profile: {
      avatarUrl: DEFAULT_AVATAR_URL,
      bannerUrl: DEFAULT_BANNER_URL,
      username: address,
    },
  };
}

export const getUserAvatar = (user?: DeprecatedPublicUser) => {
  const tokenId = user?.avatarUrl?.match(/(\d+)$\/?/)?.[0];
  if (tokenId === undefined) return DEFAULT_AVATAR_URL;

  return ensureImgixDomain(`/${tokenId}`, "moonbirds");
};

export const getTwitterIdentities = (
  user?: PublicOrAnonymousUser | null
): ThirdPartyIdentity[] => {
  return user && user?.isAnonymous !== true && user?.identities
    ? user.identities.filter(
        (identity) => identity.provider === ThirdPartyProviderName.TWITTER
      )
    : [];
};

// Encapsulates all the logic surrounding what to show for a user's 'display name'.
// We leave user.profile.displayName alone (this is what we get from identity service)
// This function constructs an object with primary/secondary display names, to be used in cases
// where multiple names/identifiers are needed.
export const getDisplayNames = (
  user?: PublicOrAnonymousUser,
  ensName?: FetchEnsNameResult,
  firstWalletAddress?: string
) => {
  if (user && !user.isAnonymous) {
    const { displayName, username } = user.profile;
    const usernameString = `@${username}`;

    // PROOF users
    if (displayName) {
      // Has display name (an optional field)
      return {
        primaryDisplayName: displayName,
        secondaryDisplayName: usernameString,
      };
    } else {
      // No display name
      return {
        primaryDisplayName: usernameString,
        secondaryDisplayName: ensName
          ? ensName
          : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            truncateEthAddress(firstWalletAddress!),
      };
    }
  } else if (firstWalletAddress) {
    // Anonymous users
    if (ensName) {
      return {
        primaryDisplayName: ensName,
        secondaryDisplayName: truncateEthAddress(firstWalletAddress),
      };
    } else {
      return {
        primaryDisplayName: truncateEthAddress(firstWalletAddress),
        secondaryDisplayName: "",
      };
    }
  } else {
    // We'll only end up here if everything is undefined
    return {
      primaryDisplayName: "",
      secondaryDisplayName: "",
    };
  }
};
