import { QueryFunctionContext, useQuery } from "react-query";

import { authenticatedFetch } from "src/queries/identity";

const fetchAddressExists = async (address: string): Promise<boolean> => {
  const resp = await authenticatedFetch(`/v1/addresses/${address}/exists`);
  if (resp.ok) {
    return await resp.json();
  } else {
    throw new Error(resp.statusText);
  }
};

export const addressExistsQuery = async ({
  queryKey,
}: QueryFunctionContext<[key: string, address: string]>): Promise<boolean> => {
  const [_key, address] = queryKey;
  return await fetchAddressExists(address);
};

export const useAddressExistsQuery = (address?: string) => {
  return useQuery(
    // eslint-disable-next-line
    ["addressExistsQuery", address!],
    addressExistsQuery,
    {
      enabled: !!address,
    }
  );
};
