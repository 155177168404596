import { Text, ButtonStyle, Anchor, Caps } from "@proofxyz/moonlight";
import cx from "classnames";
import Image from "next/image";
import Link from "next/link";

import DiscordLogo from "public/images/icons/discord.svg";
import TwitterLogo from "public/images/icons/twitter.svg";
import { POST_SIGNOUT_LANDING_PAGE } from "src/config/middleware";
import { useLandingPage } from "src/hooks/useLandingPage";

export default function AlternateSignInOptions() {
  const landingPage = useLandingPage();

  const encodedRedirectUrl = encodeURIComponent(landingPage);
  // If there is an error with third party auth, go back to the /signin page, where an error message will be displayed
  const encodedRedirectErrorUrl = encodeURIComponent(POST_SIGNOUT_LANDING_PAGE);

  return (
    // This should be a react fragment; these can be sensitive to spacing due to `gap` on the parent
    <>
      <div className="flex flex-col">
        <p className={cx(Caps.md, "text-content-secondary text-center mb-4")}>
          Alternate sign-in options
        </p>

        <Text color="secondary">
          If you already created an account and authorized Twitter, Google or Discord,
          you may sign in without connecting your wallet.
        </Text>
      </div>

      <div className="flex flex-col gap-2">
        <Link
          href={`${process.env.NEXT_PUBLIC_IDENTITY_API_URL}/v1/identities/twitter/?redirectUrl=${encodedRedirectUrl}&redirectErrorUrl=${encodedRedirectErrorUrl}`}
          passHref
        >
          <Anchor style={ButtonStyle.secondary} fullWidth>
            <div className="mr-2 h-6">
              <TwitterLogo className="fill-[#1DA1F2] w-8 -mt-1" />
            </div>
            Sign in with Twitter
          </Anchor>
        </Link>
        <Link
          href={`${process.env.NEXT_PUBLIC_IDENTITY_API_URL}/v1/identities/google/?redirectUrl=${encodedRedirectUrl}&redirectErrorUrl=${encodedRedirectErrorUrl}`}
          passHref
        >
          <Anchor style={ButtonStyle.secondary} fullWidth>
            <div className="mr-2 h-6">
              <Image
                src="/images/icons/googleLogo.svg"
                width={24}
                height={24}
                alt="google logo"
              />
            </div>
            Sign in with Google
          </Anchor>
        </Link>
        <Link
          href={`${process.env.NEXT_PUBLIC_IDENTITY_API_URL}/v1/identities/discord/?redirectUrl=${encodedRedirectUrl}&redirectErrorUrl=${encodedRedirectErrorUrl}`}
          passHref
        >
          <Anchor style={ButtonStyle.secondary} fullWidth>
            <div className="mr-2 h-6 w-7">
              <DiscordLogo className="fill-current w-8 -mt-1" />
            </div>
            Sign in with Discord
          </Anchor>
        </Link>
      </div>
    </>
  );
}
