import { useQuery, type QueryFunctionContext } from "react-query";

import { addressQuery } from "src/queries/identity";
import { proofContract } from "src/web3/contracts";

const fetchBalance = async ({ queryKey }: QueryFunctionContext<QueryKey>) => {
  const [_key, ...addresses] = queryKey;

  const holdings = await Promise.all(
    addresses.map(async (address) => {
      const resp = await proofContract.balanceOf(address);
      return resp.toNumber();
    })
  );

  return holdings.reduce((prev, curr) => prev + curr, 0);
};

type QueryKey = ["isProofCollectiveHolder", ...string[]];
export const useIsProofCollectiveHolder = (address?: string) => {
  const { data: account, isSuccess: identitySuccess } = useQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    address!,
    addressQuery,
    {
      enabled: address !== undefined,
      retry: false,
    }
  );

  // We sort the addresses to ensure a stable sort key, since array order matters
  const addresses =
    identitySuccess && account.addresses
      ? account.addresses
          .map((a) => a.value)
          .sort((a, b) => {
            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
          })
      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        [address!];

  const { data, isSuccess, ...other } = useQuery(
    ["isProofCollectiveHolder", ...addresses],
    fetchBalance,
    {
      enabled: !!address,
      retry: false,
    }
  );

  const isHolder = isSuccess && data > 0;

  return {
    isHolder,
    isSuccess,
    ...other,
  };
};
