import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { QueryFunctionContext, useQuery } from "react-query";
import { useMedia } from "use-media";

import { assertIsNode } from "src/lib/types";

import { combinedSearch } from "../lib/combinedSearch";
import { useActionKey } from "./useActionKey";
import useDebounce from "./useDebounce";

const searchQuery = async ({
  queryKey,
}: QueryFunctionContext<["search", string]>) => {
  const [_key, query] = queryKey;
  return await combinedSearch(query);
};

export default function useSearch(
  searchContainerRef: React.RefObject<HTMLDivElement>,
  inputRef: React.RefObject<HTMLInputElement>,
  { bindEventListeners = false, actionKey = "k" },
  onClick: () => void
) {
  const router = useRouter();
  const [query, setQuery] = useState("");
  const [resultsVisible, setResultsVisible] = useState(false);
  const [selectedResult, setSelectedResult] = useState(0);
  const actionKeyText = useActionKey();
  const debouncedQuery = useDebounce(query, 300);

  const { data: results, isLoading } = useQuery(
    ["search", debouncedQuery],
    searchQuery,
    {
      enabled: debouncedQuery.length > 0,
    }
  );

  const hasResults =
    results &&
    (results.artists?.length ||
      results.accounts?.length ||
      results.nfts?.length ||
      results.grails?.length);

  // Handles the visibility of the search results
  useEffect(() => {
    if (query.length > 0 && hasResults) {
      setResultsVisible(true);
    } else {
      setResultsVisible(false);
    }
  }, [query, hasResults]);

  // Handles the global events that control the results visibility
  useEffect(() => {
    const listenForTrigger = (event: KeyboardEvent) => {
      if (
        inputRef.current &&
        event.key === actionKey &&
        (event.metaKey || event.ctrlKey)
      ) {
        inputRef.current.focus();
      }
    };

    const closeResults = (event: Event) => {
      assertIsNode(event.target);
      if (!searchContainerRef.current?.contains(event.target)) {
        setResultsVisible(false);
      }
    };

    if (bindEventListeners) {
      document.addEventListener("keydown", listenForTrigger);
      document.addEventListener("click", closeResults, false);
      document.addEventListener("focus", closeResults, true);
    }

    return () => {
      document.removeEventListener("keydown", listenForTrigger);
      document.removeEventListener("click", closeResults, false);
      document.removeEventListener("focus", closeResults, true);
    };
  }, [inputRef, searchContainerRef, bindEventListeners, actionKey]);

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!hasResults) return;

    // Build an ordered list of links. When the user hits enter,
    // navigate to the link with index === selectedResult
    const links = [
      results?.artists.map((artist) => `/artists/${artist.slug}`),
      results?.accounts.map((account) => `/u/${account.profile.username}`),
      results?.grails.map(
        (grail) =>
          `/grails/season-${grail.season}/${grail.slot
            .toString()
            .padStart(2, "0")}`
      ),
      results?.nfts.map((nft) => nft.link),
    ].flat();

    const totalCount = links.length;

    switch (e.key) {
      case "Escape":
        return setResultsVisible(false);
      case "ArrowDown":
        return setSelectedResult(Math.min(selectedResult + 1, totalCount - 1));
      case "ArrowUp":
        return setSelectedResult(Math.max(selectedResult - 1, 0));
      case "Enter": {
        setResultsVisible(false);
        return router.push(links[selectedResult]);
      }
    }
  };

  let placeholderText = "";
  if (useMedia({ minWidth: 370 })) {
    placeholderText += "Search";
  }
  if (useMedia({ minWidth: 680 }) && actionKeyText && bindEventListeners) {
    placeholderText += ` (${actionKeyText[0]}K)`;
  }

  const handleChange = (newValue: string) => {
    setQuery(newValue);
    setSelectedResult(0);
  };

  return {
    inputProps: {
      placeholder: placeholderText,
      value: query,
      onChange: handleChange,
      onClick: () => {
        if (hasResults && !resultsVisible) {
          setResultsVisible(true);
        }
        onClick();
      },
      onKeyDown: keyDownHandler,
      onFocus: () => {
        if (hasResults) {
          setResultsVisible(true);
        }
      },
    },
    query,
    results,
    resultsVisible,
    isLoading,
    selectedResult,
    setSelectedResult,
    clearQuery: () => handleChange(""),
  };
}
