import type { BigNumberish } from "ethers";
import { useQuery, type QueryFunctionContext } from "react-query";

import { moonbirdsInChainFeaturesRegistryContract } from "src/web3/contracts";

async function hasFeaturesQueryFunction(
  context: QueryFunctionContext<[string, BigNumberish]>
) {
  const tokenId = context.queryKey[1];
  return await moonbirdsInChainFeaturesRegistryContract.hasFeatures(tokenId);
}

export function useMoonbirdInChainQuery(tokenId: number | string) {
  const normalTokenId = normalizeTokenId(tokenId);
  const isValidTokenId =
    !isNaN(normalTokenId) && normalTokenId >= 0 && normalTokenId < 10000;

  return useQuery(["hasFeatures", normalTokenId], hasFeaturesQueryFunction, {
    enabled: isValidTokenId,
  });
}

export function useMoonbirdInChain(tokenId: number | string) {
  const { data } = useMoonbirdInChainQuery(tokenId);

  return data;
}

function normalizeTokenId(tokenId: number | string) {
  return typeof tokenId === "string" ? parseInt(tokenId, 10) : tokenId;
}
