import classnames from "classnames/bind";
import { useRouter } from "next/router";
import React, { useState } from "react";
import useMedia from "use-media";

import InChainAvatar from "src/components/InChainAvatar";
import { useIdentity } from "src/hooks/useIdentity";

import ProfileDropdownContents from "./ProfileDropdownContents";
import dropdownStyles from "./dropdowns.module.css";

const cx = classnames.bind(dropdownStyles);

const ProfileDropdown: React.FunctionComponent = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { viewer } = useIdentity();

  const isDesktop = useMedia({ minWidth: 768 });
  const isMobile = !isDesktop;

  return (
    <div
      className={cx("headerDropdown")}
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <button
        className="flex items-center gap-2"
        onClick={() => {
          if (isMobile) {
            setIsOpen(!isOpen);
          } else {
            router.push(`/u/${viewer?.profile.username}`);
          }
        }}
      >
        <InChainAvatar src={viewer?.profile.avatarUrl} />
      </button>

      <div
        className={cx("dropdownMenu", "dropdownMenu--profile", {
          "dropdownMenu--open": isOpen,
        })}
      >
        <div className={cx("dropdownContents", "dropdownContents--box")}>
          <ProfileDropdownContents />
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
