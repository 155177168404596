import cx from "classnames";
import React from "react";

import CaretIcon from "src/icons/caret.svg";

import styles from "./Caret.module.css";
import { CaretProps, Direction } from "./Caret.types";

const Caret: React.FunctionComponent<CaretProps> = ({
  direction = Direction.UP,
  color = "primary",
  size = "lg",
  hoverable = false,
  onClick,
  className,
}) => {
  const classNames = cx(
    {
      [styles.hoverable]: hoverable,
      [styles.down]: direction === Direction.DOWN,
      [styles.left]: direction === Direction.LEFT,
      [styles.right]: direction === Direction.RIGHT,
      [styles.primary]: color === "primary",
      [styles.secondary]: color === "secondary",
      [styles.inactive]: color === "inactive",
      [styles.disabled]: color === "disabled",
      "fill-current": color === "current",
    },
    className
  );

  const dimensionInPixels = size === "sm" ? 16 : 24;

  return (
    <CaretIcon
      height={dimensionInPixels}
      width={dimensionInPixels}
      className={classNames}
      onClick={onClick}
    />
  );
};

export default Caret;
