import { Button, ButtonStyle, Color } from "@proofxyz/moonlight";
import classnames from "classnames/bind";
import throttle from "lodash/throttle";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useMedia } from "use-media";
import { useNetwork, useSwitchNetwork } from "wagmi";

import SignInButton from "src/components/signin/SignInButton";
import { useIdentity } from "src/hooks/useIdentity";
import Close from "src/icons/close.svg";
import Hamburger from "src/icons/hamburger.svg";
import ProofGlyph from "src/icons/proofGlyph.svg";
import { defaultChainId } from "src/web3/constants";

import SearchBox from "../Search/SearchBox";
import styles from "./Header.module.css";
import MobileNavigationMenu from "./MobileNavigationMenu";
import Navigation from "./Navigation";
import ThemeToggle from "./ThemeToggle";
import ProfileDropdown from "./dropdowns/ProfileDropdown";

const cx = classnames.bind(styles);

const Header: React.FunctionComponent<{ transparentUntil?: number }> = ({
  transparentUntil,
}) => {
  const { isAuthenticated } = useIdentity();
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState<boolean>(false);
  const [transparencyActive, setTransparencyActive] = useState(
    !!transparentUntil
  );
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const [hasSearchQuery, setHasSearchQuery] = useState(false);
  const hideRightGroup = useMedia({ maxWidth: 420 }) && hasSearchQuery;

  const checkTransparency = throttle(() => {
    if (!transparentUntil) return;
    if (window.scrollY > transparentUntil && transparencyActive) {
      setTransparencyActive(false);
    } else if (window.scrollY < transparentUntil && !transparencyActive) {
      setTransparencyActive(true);
    }
  }, 50);

  useEffect(() => {
    if (transparentUntil) {
      checkTransparency();
      document.addEventListener("scroll", checkTransparency);
    } else {
      setTransparencyActive(false);
    }

    return () => {
      document.removeEventListener("scroll", checkTransparency);
    };
  }, [checkTransparency, transparentUntil]);

  const wrongNetwork = switchNetwork && chain?.id !== defaultChainId;

  return (
    <>
      <div className={styles.Header}>
        {wrongNetwork && (
          <div className={styles.WrongNetwork}>
            You are connected to the wrong network.{" "}
            <button
              className="underline cursor-pointer"
              onClick={() => switchNetwork(defaultChainId)}
            >
              Click here to fix.
            </button>
          </div>
        )}
        <header
          className={cx({
            transparent: transparencyActive && !hamburgerMenuOpen,
          })}
        >
          <div className={styles.Header__inner}>
            {/* Left group */}
            <div className={styles.leftGroup}>
              <Link href="/">
                <a>
                  <ProofGlyph className={cx("ProofGlyph", "w-10")} />
                </a>
              </Link>

              <SearchBox
                setHasSearchQuery={setHasSearchQuery}
                onClick={() => setHamburgerMenuOpen(false)}
              />

              {/* Navigation items are oly shown when >= sm (640px). ml, plus the
                gap on the parent container, should eq the gap between nav items */}
              <div className="hidden sm:block ml-2 lg:ml-6">
                <Navigation />
              </div>
            </div>

            {/* Right group */}
            {hideRightGroup ? null : (
              <div className={styles.rightGroup}>
                {<ThemeToggle />}

                {isAuthenticated ? (
                  <ProfileDropdown />
                ) : (
                  <SignInButton color={Color.purple} />
                )}

                {/* Hamburger button - only show when less than 640px */}
                <div className="block sm:hidden">
                  <Button
                    icon={hamburgerMenuOpen ? <Close /> : <Hamburger />}
                    style={ButtonStyle.secondary}
                    color={Color.neutral}
                    onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}
                  />
                </div>
              </div>
            )}
          </div>

          {hamburgerMenuOpen && <MobileNavigationMenu />}
        </header>
      </div>

      {/* This little guy is what pushes the content of the page down based on the size of the header */}
      <div
        className={cx("w-full", {
          "h-20": !wrongNetwork && !transparentUntil,
          "h-28": wrongNetwork && !transparentUntil,
          "h-8": wrongNetwork && transparentUntil,
        })}
      />
    </>
  );
};

export default Header;
