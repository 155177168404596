import { contracts, IMGIX_NFT_URL } from "src/web3/constants";

export function parseMoonbirdsTokenId(url?: string | null) {
  if (!url || typeof url !== "string") return NaN;
  const matchers = [
    /^https:\/\/moonbirds.imgix.net\/(\d+)(\?.+)?$/,
    new RegExp(`^${IMGIX_NFT_URL}/${contracts.MOONBIRDS}/(\\d+)`, "i"),
  ];

  for (let i = 0; i < matchers.length; i++) {
    const tokenId = matchers[i].exec(url)?.[1];
    if (tokenId !== undefined) return Number(tokenId);
  }

  return NaN;
}

export function getMoonbirdsInChainImageUrl(tokenId: string | number) {
  return `/api/moonbirds/${tokenId}/image`;
}
