import type { AvatarProps } from "@proofxyz/moonlight/src/Avatar/Avatar.types";
import { useQuery } from "react-query";
import truncateEthAddress from "truncate-eth-address";
import { useEnsName } from "wagmi";

import { useIsProofCollectiveHolder } from "src/hooks/useIsProofCollectiveHolder";
import { addressQuery } from "src/queries/identity";

type DisplayNameAndAvatar = {
  displayName?: string;
  avatarUrl?: AvatarProps["src"];
  profileLink: string;
  proofCollective: boolean;
};

// TOOD - refactor this using new useProofUser hook when it's available
export const useDisplayInfoForAddress = (
  address?: string
): DisplayNameAndAvatar => {
  const { data: account, isSuccess: identitySuccess } = useQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    address!,
    addressQuery,
    {
      enabled: address !== undefined,
      retry: false,
    }
  );

  const { data: ensName, isSuccess: ensSuccess } = useEnsName({ address });
  const { isHolder } = useIsProofCollectiveHolder(address);

  if (address === undefined) {
    return {
      profileLink: "#",
      proofCollective: false,
    };
  }

  if (identitySuccess) {
    return {
      displayName: `@${account.profile.username}`,
      avatarUrl: account.profile.avatarUrl,
      profileLink: `/u/${account.profile.username}`,
      proofCollective: isHolder,
    };
  }

  if (ensSuccess && ensName) {
    return {
      displayName: ensName,
      profileLink: `/u/${ensName}`,
      proofCollective: isHolder,
    };
  }

  return {
    displayName: truncateEthAddress(address),
    profileLink: `/u/${address}`,
    proofCollective: isHolder,
  };
};
