import { Modal } from "@proofxyz/moonlight";

import ConnectorOptions from "src/components/connect/ConnectorOptions";

type ConnectModalProps = {
  onRequestClose: () => void;
  allowClose?: boolean;
};

export default function ConnectModal({
  onRequestClose,
  allowClose = true,
}: ConnectModalProps) {
  return (
    <Modal
      className="max-w-lg"
      title="Select a wallet"
      onRequestClose={onRequestClose}
      allowClose={allowClose}
    >
      <div className="flex flex-col gap-6">
        <ConnectorOptions isLoaded />
      </div>
    </Modal>
  );
}
