import { Text } from "@proofxyz/moonlight";
import classnames from "classnames/bind";

import { useDarkMode, Modes } from "src/hooks/useDarkMode";
import Daylight from "src/icons/daylight.svg";
import Moonlight from "src/icons/moonlight.svg";

import styles from "./Header.module.css";

const cx = classnames.bind(styles);

export default function ThemeToggle() {
  const { mode: uiMode, setMode, isForced } = useDarkMode();
  const isLight = uiMode === Modes.LIGHT;
  const IconComponent = isLight ? Daylight : Moonlight;
  const cn = cx("w-6", {
    "text-purple-500": isLight,
    "text-purple-250": !isLight,
    "text-gray-500": isForced && isLight,
    "text-gray-700": isForced && !isLight,
  });

  const cursorClass = !isForced ? "cursor-pointer" : "";

  return (
    <div className={cx("ThemeToggle", cursorClass)}>
      <IconComponent
        className={cn}
        onClick={() => {
          if (!isForced) {
            setMode(isLight ? Modes.DARK : Modes.LIGHT);
          }
        }}
      />

      <Text color="secondary" size="sm" className={cx("ThemeToggle__label")}>
        {isLight ? "Light" : "Dark"} mode
      </Text>
    </div>
  );
}
